import "./bootstrap";

$(function() {

    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
        }
    });

    // get API names
    $.getJSON("../json/api.json").done(function(APIs) {

        // load images from JSON list
        const images = [];
        let resources = [];
        let loaded = 0;
        $.getJSON("../json/images.json").done(function(resources) {

            const count = resources.length;

            for (let i=0; i<count; i++) {

                // create new image container
                let image = new Image();
                images.push(image);
                image.onload = function() {

                    loaded++; // next image in list

                    // loading done
                    if (loaded === count) {
                        $("#loading").hide();
                        $("#content").show();

                        loadAPIData(APIs);
                    }
                }

                // load image into image container
                image.src = resources[i].img;
            }
        });
    });

    async function loadAPIData(APIs) {
        try {
            // make API requests in parallel and await their completion
            await Promise.all(APIs.map(api => requestAPI(api.name)));
        } catch (error) {
            // console.error("API error", error);
        }
    }

    async function requestAPI(projectName) {
        try {
            // load data from API by project name
            const response = await axios.post("api", { projectName });

            // response data is array
            if (Array.isArray(response.data)) {
                handleResponseArray(projectName, response);
            }
            // response data is object
            else {
                handleResponseObject(projectName, response);
            }
        } catch (error) {
            // console.error(`API error loading ${projectName}`, error);
        }
    }

    function handleResponseArray(projectName, response) {

        // concatenate response data
        let data = "";
        response.data.forEach((item, index) => {
            if (index < response.data.length - 1) {
                data = data + item + ", ";
            } else {
                data = data + item;
            }
        });
        
        // hide individual loading spinner
        const loadingID = "#loading-" + projectName;
        $(loadingID).hide();

        // identify project data element by project name
        const dataID = "#data-" + projectName;
        const showID = "#show-" + projectName;

        // thousand separator for numbers
        if (!isNaN(data)) {
            data = data.toLocaleString();
        }

        // add response data to element
        $(dataID).html(data);
        $(showID).show();
    }

    function handleResponseObject(projectName, response) {

        // get response data
        let data = response.data;

        // special case for YouTube API
        if (projectName === "youtube") {

            // get data and keys
            data = response.data.items[0].statistics;

            // remove unnecessary items from data and keys
            if (data.hasOwnProperty("hiddenSubscriberCount")) {
                delete data.hiddenSubscriberCount;
            }

            // convert data values to numbers
            for (const [key, value] of Object.entries(data)) {
                data[key] = Number(value);
            }
        }

        // special case for Doppelganger itch.io API
        if (projectName === "doppelganger") {

            // get data and keys
            data = response.data.games[0];

            // convert data values to numbers
            for (const [key, value] of Object.entries(data)) {
                data[key] = Number(value);
            }
        }

        // special case for NAGUAL itch.io API
        if (projectName === "nagual") {

            // get data and keys
            data = response.data.games[1];

            // convert data values to numbers
            for (const [key, value] of Object.entries(data)) {
                data[key] = Number(value);
            }
        }

        // get project data keys
        let keys = Object.keys(data);

        // show project data
        for (const key of keys) {

            // hide individual loading spinner
            const loadingID = "#loading-" + projectName + "-" + key;
            $(loadingID).hide();

            // identify project data element by project name and key
            const dataID = "#data-" + projectName + "-" + key;
            const showID = "#show-" + projectName + "-" + key;

            let keydata = data[key];
            
            // thousand separator for numbers
            if (!isNaN(data[key])) {
                data[key] = data[key].toLocaleString();
            }

            // add response data to element
            $(dataID).html(data[key]);
            $(showID).show();
        }
    }
});